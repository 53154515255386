import { render, staticRenderFns } from "./PersonOverviewResults.vue?vue&type=template&id=c2549a96"
import script from "./PersonOverviewResults.vue?vue&type=script&lang=js"
export * from "./PersonOverviewResults.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardPerson: require('/home/userapp/components/Molecule/Card/Person.vue').default})
