
import xor from 'lodash/xor'
import { mapGetters, mapMutations } from 'vuex'
import { setupTranslationPrefixer } from '~/assets/translation'
import filterLabel from '~/mixins/filterLabel'
import FacetBlock from '@/components/Molecule/FacetBlock.vue'

export default {
  components: { FacetBlock },
  mixins: [filterLabel],
  setup(props) {
    return {
      ...setupTranslationPrefixer('OverviewFilterPanel'),
    }
  },
  data() {
    return {
      panelState: false,
      closedFacetBlocks: [],
    }
  },
  computed: {
    ...mapGetters({
      facets: 'overview/facets',
      activeFacets: 'overview/activeFacets',
      totalHits: 'overview/totalHits',
    }),
  },
  methods: {
    ...mapMutations({
      reset: 'overview/reset',
      toggleFacet: 'overview/toggleFacet',
    }),
    isFacetBlockOpen(name) {
      return !this.closedFacetBlocks.includes(name)
    },
    toggleOpenFacetBlock(name) {
      this.closedFacetBlocks = xor(this.closedFacetBlocks, [name])
    },
  },
}
