import { render, staticRenderFns } from "./Overview.vue?vue&type=template&id=13023bd0&scoped=true"
import script from "./Overview.vue?vue&type=script&lang=js"
export * from "./Overview.vue?vue&type=script&lang=js"
import style0 from "./Overview.vue?vue&type=style&index=0&id=13023bd0&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13023bd0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControlPanel: require('/home/userapp/components/Molecule/ControlPanel.vue').default,Skiplink: require('/home/userapp/components/Molecule/Skiplink.vue').default,Breadcrumbs: require('/home/userapp/components/Organism/Breadcrumbs.vue').default,IconDot: require('/home/userapp/components/Atom/Icon/IconDot.vue').default,TypographyHeading: require('/home/userapp/components/Atom/Typography/TypographyHeading.vue').default,TypographyIntroduction: require('/home/userapp/components/Atom/Typography/TypographyIntroduction.vue').default,OverviewSearch: require('/home/userapp/components/Organism/Overview/OverviewSearch.vue').default,OverviewFilterPanel: require('/home/userapp/components/Organism/Overview/OverviewFilterPanel.vue').default,OverviewResultSummary: require('/home/userapp/components/Organism/Overview/OverviewResultSummary.vue').default,PersonOverviewResults: require('/home/userapp/components/Organism/PersonOverview/PersonOverviewResults.vue').default,OverviewResults: require('/home/userapp/components/Organism/Overview/OverviewResults.vue').default,OverviewPagination: require('/home/userapp/components/Organism/Overview/OverviewPagination.vue').default,Base: require('/home/userapp/components/Template/Base.vue').default})
