
import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { routerQueryIsEqual } from '~/assets/dynamic-url'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  props: {
    page: { type: Object, required: true },
  },
  setup(props) {
    return {
      ...setupTranslationPrefixer('Overview'),
    }
  },
  computed: {
    ...mapGetters({
      activeFacets: 'overview/activeFacets',
      facets: 'overview/facets',
      urlParameters: 'overview/urlParameters',
    }),
  },
  watch: {
    urlParameters(query) {
      if (!routerQueryIsEqual(this.$route.query, query)) {
        this.$router.push({ query })
      }
    },
  },
})
