
import { mapGetters } from 'vuex'
import { setupTranslationPrefixer } from '~/assets/translation'

export default {
  setup() {
    return {
      ...setupTranslationPrefixer('overview-person'),
    }
  },
  computed: {
    ...mapGetters({
      results: 'overview/results',
    }),
  },
  methods: {
    getCallablePhoneNumber(phoneNumber) {
      // Strip spaces and other characters, except digits 0-9 and the plus sign for net numbers.

      if (phoneNumber !== null) {
        phoneNumber = phoneNumber.replace(/\s/g, '')

        if (phoneNumber.charAt(0) === '+' && phoneNumber.includes('(0)')) {
          // Net number is present, remove excessive '(0)' if present at the same time
          const index = phoneNumber.indexOf('(0)')
          phoneNumber = phoneNumber.slice(0, index) + phoneNumber.slice(index + 3)
        }

        phoneNumber = phoneNumber.replace(/[^+0-9]/g, '')

        return phoneNumber
      }
    },
  },
}
